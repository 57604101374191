<template>
  <v-list-item @click="OnClickLogOff()">
    <v-list-item-icon class="my-auto">
      <v-icon color="pink accent-2"> mdi-cogs </v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title
        class="grey--text text--darken-4"
        style="font-size: 4vw; font-weight: bold"
        >ออกจากระบบ</v-list-item-title
      >
      <!-- <v-list-item-subtitle class="text--primary"
                >กำลังทำแทง</v-list-item-subtitle
              > -->
      <!-- <v-list-item-subtitle class="mt-2">36 ID</v-list-item-subtitle> -->
    </v-list-item-content>

    <v-list-item-action>
      <v-icon color="pink accent-1"> mdi-arrow-right-drop-circle </v-icon>

      <!-- <v-list-item-action-text>เปิดครั้งละ</v-list-item-action-text> -->
      <!-- 6 ID -->
      <!-- <v-icon color="green">
            mdi-circle
          </v-icon> -->
    </v-list-item-action>
  </v-list-item>
</template>

<script>
export default {
  name: "ListHP",
  components: {
    // components,
  },

  //mounted: {},
  computed: {},
  methods: {
    OnClickLogOff() {
      this.$confirm("กด OK เพื่อยืนยันการออกจากระบบ", "ยืนยัน", "question")
        .then(() => {
          localStorage.removeItem("fun_token");
          localStorage.removeItem("error_count");
          localStorage.removeItem("error_varify_time");
          this.$router.push("/Connector");
        })
        .catch(() => {});
    },
  },
  data: () => ({}),
};
</script>

<style></style>
