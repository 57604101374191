<template>
  <v-container fluid grid-list-xs style="background-color: transparent">
    <!-- fill-height ทำให้ข้างในอยู่กลาง -->
    <v-layout row wrap full-width>
      <v-flex
        xs12
        text-center
        class="py-3"
        style="font-size: 6vw; font-weight: bold; border-bottom: 2px pink solid"
      >
        <!-- class="text-right mr-1 mt-2 mr-2 pink--text text--darken-1" -->
        ตั้งค่า
      </v-flex>
      <v-flex xs12>
        <!-- class="text-right mr-1 mt-2 mr-2 pink--text text--darken-1" -->
        <v-list style="background-color: transparent">
          <v-list-item-group active-class="pink--text">
            <ListChangePeriod />
            <v-divider></v-divider>
            <ListChangePassword />
            <v-divider></v-divider>
            <ListLogoff />
            <v-divider></v-divider>
          </v-list-item-group>
        </v-list>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import ListChangePeriod from "@/components/Setting/Main/List/ListChangePeriod";
import ListChangePassword from "@/components/Setting/Main/List/ListChangePassword";
import ListLogoff from "@/components/Setting/Main/List/ListLogoff";
export default {
  components: {
    ListChangePeriod,
    ListChangePassword,
    ListLogoff,
  },
  data: () => ({
    HostID: sessionStorage.getItem("HostID"),
    LineID: sessionStorage.getItem("LineID"),
    AgentID: sessionStorage.getItem("AgentID"),
    PeriodDT: sessionStorage.getItem("PeriodDT"),

    CloseDT: "",
    IsTest: 0,
  }),
};
</script>

<style scoped>
/* .absolute {
    position: absolute;
    bottom: 10px;
    width: 50%;
    border: 3px solid #8ac007;
  }
  .top {
    background-color: azure;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    align-self: flex-start;
    width: 100%;
    flex: 0 1 40px
  }
  .content {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 60px;
  }
  .footer {
      flex: 0 1 45px;
      background-color: lightcyan;
      margin-bottom: 0;
      padding-bottom: 0;
  } */
</style>
